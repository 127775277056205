<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <div>
          <span>Masuk</span>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
            />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button
            @click="submitForm"
            v-if="!isLoading"
            type="submit"
            class="btn btn-gradient"
          >
            Kirim
          </button>
          <button v-else class="btn btn-gradient">Loading...</button>
        </div>
        <h2>
          Belum mempunyai akun?
          <router-link to="/auth/register" class="primary-link">
            Daftar di sini
          </router-link>
        </h2>
        <h2>
          Lupa password?
          <router-link to="/auth/forgot-password" class="primary-link">
            Klik di sini
          </router-link>
        </h2>
        <h2>
          Belum menerima email verifikasi?
          <router-link
            to="/auth/email-verification-token-request"
            class="primary-link"
          >
            Klik di sini
          </router-link>
        </h2>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
    TogglePassword,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      email: "",
      password: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,

      recaptchaToken: "",
      isLoading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;

      // TODO: Jangan bypass di production
      // if (self.recaptchaToken != "") {
      self.isLoading = true;
      var res = await thestore.postAuthLogin(
        self.email,
        self.password,
        self.recaptchaToken
      );

      if (res.success == true && res.action == "login_complete") {
        Cookies.set("jwt", res.token, {
          expires: 7,
          secure: true,
          sameSite: "lax",
        }); // expire 7 days. CAUTION: coba kurangi jika perlu
        Cookies.set("usid", res.usid, {
          expires: 7,
          secure: true,
          sameSite: "lax",
        });
        self.$router.push("/");
      } else if (res.success == true && res.action == "2fa") {
        Cookies.set("temp_email", self.email, {
          expires: 1,
          secure: true,
          sameSite: "lax",
        });
        Cookies.set("temp_password", self.password, {
          expires: 1,
          secure: true,
          sameSite: "lax",
        });
        self.$router.push("/auth/two-factor-auth");
      } else {
        toast.warning(res.msg);
        if (res.msg == "Wrong captcha") {
          self.$router.go();
        }

        self.isLoading = false;
      }
      // }
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },
  },
};
</script>
