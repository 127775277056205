<template>
  <div class="b_wrapp">
    <h2 class="text-light">
      {{ currency }}
      <small>({{ activeCurrencyObj.ccurrency_name }})</small>
    </h2>

    <div class="row mb-4 mt-4 mr-2 head_t">
      <div class="d-flex text-light b_itm">
        <div class="b_itm-i">
          <span>Balance:</span>
          <h4>
            {{ (parseFloat(balanceObj.saldo_value) || 0.0000).numberFormat(floatDigit) }}
            {{ currency }}
          </h4>
        </div>
        <div class="b_itm-i">
          <span>Lock: </span>
          <h4>
            {{ (parseFloat(balanceObj.saldo_lock) || 0.0000).numberFormat(floatDigit) }}
            {{ currency }}
          </h4>
        </div>
      </div>

      <div class="menu_wrapp">
        <div
          @click="handleDigitMenu"
          @mouseleave="isActive = false"
          class="dex_digit"
        >
          <div class="digit_item">
            <span>{{ valueSelected }}</span>
            <svg
              width="15"
              height="7"
              viewBox="0 0 19 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.2195 0L9.10975 10.9317L0 0H18.2195Z"
                fill="aliceblue"
              />
            </svg>
          </div>

          <div class="digit_lists" :class="{ menu_active: isActive }">
            <span @click="changeDigitFloat('0.0001')">0.0001</span
            ><span @click="changeDigitFloat('0.001')">0.001</span
            ><span @click="changeDigitFloat('0.01')">0.01</span
            ><span @click="changeDigitFloat('0.1')">0.1</span
            ><span @click="changeDigitFloat('1')">1</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { mapState } from "vuex";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

export default {
  components: {},
  props: {
    currency: {
      type: String,
      required: true,
    },
    activeCurrencyObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      balanceObj: {},

      isActive: false,
    };
  },

  computed: {
    ...mapState(["valueSelected", "floatDigit"]),
  },

  sockets: {
    connect: function () {
      // PERHATIAN: JANGAN DI COMMENT LOG INI KARENA DI PRODUCTION AKAN MENYEBABKAN ERROR
      console.log("socket connected");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        if (parseInt(self.usid) == el.u) {
          self.getBalance();
        }
      });
    },
  },

  async mounted() {
    var self = this;
    await self.getBalance();
  },
  methods: {
    async getBalance() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getBalances(
        self.token,
        1,
        1,
        "id",
        "asc",
        filter_rules
      );
      self.balanceObj = res.datas[0];
    },

    handleDigitMenu() {
      var self = this;

      self.isActive = !self.isActive;
    },

    changeDigitFloat(val) {
      var self = this;

      this.$store.dispatch("changeDigitFloat", val);

      self.getBalance();
    },
  },
};
</script>

<style scoped>
.head_t {
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: space-between;
}

.b_itm {
  gap: 50px;
  align-items: center;
  margin-left: 10px;
}

.b_itm-i {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.b_itm-i span {
  color: #009B67;
}

.menu_wrapp {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.dex_digit {
  position: relative;
  cursor: pointer;
  margin-top: -50px;
}

.digit_item {
  width: 70px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #343A40;
  padding: 0 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.digit_item span {
  color: aliceblue;
}

.digit_lists {
  position: absolute;
  /* margin-right: 10px; */
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: #262c34;
  border: 1px solid #262c34;
  border-radius: 3px;
  width: 70px;
  z-index: 999;

  transition-duration: 0.5s;
}

.menu_active {
  display: flex;
}

.digit_lists span {
  color: aliceblue;
  width: 100%;
  display: flex;
  justify-content: center;
}

.digit_lists span:hover {
  background-color: #343A40;
}
</style>
