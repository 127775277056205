<template>
  <header class="dark-bb">
    <nav
      :class="`navbar navbar-expand-lg ${fixedTop} ${navbarScrolled} ${
        width < 992 && 'bg_color'
      }`"
    >
      <router-link class="navbar-brand" to="/">
        <img
          src="/images/logo-bki-01.png"
          alt="bki-logo"
          style="height: 42px"
        />
      </router-link>
      <button
        class="navbar-toggler pt-2"
        type="button"
        @click="showModal = true"
      >
        <i class="icon ion-md-menu text-light"></i>
      </button>

      <div class="collapse navbar-collapse pt-3 pt-lg-0" id="headerMenu">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="/" role="button"> Beranda </a>
          </li>
          <li class="nav-item">
            <router-link to="/markets" class="nav-link"> Pasar </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="themain.defaultMarketPair" class="nav-link">
              Pertukaran
            </router-link>
          </li>

          <li
            class="nav-item ml-3 my-auto"
            v-if="user.orang_kyc_status == 'not approve'"
          >
            <div class="nav_kyc">
              <button @click="showModalKyc = true">Mulai KYC</button>
            </div>
            <!-- <div class="nav_kyc">
              <button>Mulai KYC</button>
              <metamap-button
                ref="button"
                :clientid="themain.metamapClientId"
                :flowid="themain.metamapFlowId"
                :metadata="metadata"
                class="metamap_btn"
              >
              </metamap-button>
            </div> -->
          </li>

          <!-- jika status kyc approve tetapi belum tandatangani agreement, bisa munculkan button ini -->
          <li
            class="nav-item"
            v-if="
              user.orang_kyc_status == 'approve' &&
              (user.orang_agreement == false || user.orang_agreement == null)
            "
          >
            <router-link
              to="/settings/agreement-sign"
              class="nav_link nav_kyc-update"
              >KYC Update</router-link
            >
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <!-- jika ada token, muncul barisan notif -->
          <li
            class="nav-item dropdown header-custom-icon"
            v-if="token"
            @click="themain.openDropdown('user-notifs')"
            id="user-notifs"
          >
            <div
              class="nav-link dropdown-toggle cursor_pointer px-0 mx-3"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="icon ion-md-notifications"></i>
              <span class="circle-pulse" v-if="notifs_count > 0"></span>
            </div>
            <div class="dropdown-menu">
              <div
                class="dropdown-header d-flex align-items-center justify-content-between"
              >
                <p class="mb-0 mr-4 font-weight-medium">
                  {{ notifs_count }} New Notifications
                </p>
                <div
                  @click="postClearAllNotifs()"
                  class="text-muted cursor_pointer"
                >
                  Clear all
                </div>
              </div>
              <div class="dropdown-body">
                <div
                  class="dropdown-item"
                  v-for="item in notifs"
                  :key="item.id"
                >
                  <div class="icon">
                    <i class="icon ion-md-alert"></i>
                  </div>
                  <div class="content">
                    <p>{{ item.notif_text }}</p>
                    <p class="sub-text text-muted">
                      {{
                        moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="dropdown-footer d-flex align-items-center justify-content-center">
                <a href="#!">View all</a>
              </div> -->
            </div>
          </li>

          <!-- jika ada token, muncul icon user -->
          <li
            class="nav-item dropdown header-img-icon"
            v-if="token"
            @click="themain.openDropdown('user-menu')"
            v-bind:id="`user-menu`"
          >
            <div
              class="nav-link dropdown-toggle cursor_pointer pl-0 ml-3"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="/assets/img/avatar.svg" alt="avatar" />
            </div>
            <div class="dropdown-menu">
              <div
                class="dropdown-header d-flex flex-column align-items-center"
              >
                <div class="figure mb-3">
                  <img src="/assets/img/avatar.svg" alt="" />
                </div>
                <div class="info text-center">
                  <p class="name font-weight-bold mb-0">
                    {{ user.orang_name }}
                  </p>
                  <p class="email text-muted mb-3">
                    @{{ user.orang_nickname }}
                  </p>
                </div>
              </div>
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link :to="`/settings/profile`" class="nav-link">
                      <i class="icon ion-md-person"></i>
                      <span>Profil</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="`/settings/wallets`" class="nav-link">
                      <i class="icon ion-md-wallet"></i>
                      <span>Dompet Saya</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      @click="logoutAccount"
                      to="#"
                      class="nav-link red"
                    >
                      <i class="icon ion-md-power"></i>
                      <span>Keluar</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <!-- jika tidak ada token, muncul login dan register -->
          <li class="nav-item" v-if="!token" id="login_menu_button">
            <router-link
              to="/auth/register"
              class="btn btn-outline-orange"
              style="margin-right: 20px"
            >
              Daftar
            </router-link>
            <router-link
              to="/auth/login"
              class="btn btn-outline-yellow"
              style="margin-right: 20px"
            >
              Masuk
            </router-link>
          </li>
        </ul>
      </div>

      <Teleport to="body">
        <!-- use the modal component, pass in the prop -->
        <MenuModal
          :show="showModal"
          @close="showModal = false"
          :user="user"
          :themain="themain"
          :token="token"
          :logoutAccount="logoutAccount"
          :metadata="metadata"
        >
          <template #header>
            <div class="menu_head">
              <img src="/images/bitbromo-logo.png" alt="" width="100" />
              <svg
                @click="showModal = false"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 40 40"
                fill="none"
              >
                <path
                  d="M24.9896 20L38.956 6.03369C39.6188 5.37202 39.9916 4.47414 39.9925 3.53757C39.9933 2.601 39.622 1.70247 38.9604 1.03963C38.2987 0.376789 37.4008 0.00394636 36.4643 0.00311924C35.5277 0.00229213 34.6292 0.373549 33.9663 1.03522L20 15.0015L6.03369 1.03522C5.37085 0.372379 4.47185 0 3.53445 0C2.59706 0 1.69806 0.372379 1.03522 1.03522C0.372379 1.69806 0 2.59706 0 3.53445C0 4.47185 0.372379 5.37085 1.03522 6.03369L15.0015 20L1.03522 33.9663C0.372379 34.6292 0 35.5282 0 36.4655C0 37.4029 0.372379 38.3019 1.03522 38.9648C1.69806 39.6276 2.59706 40 3.53445 40C4.47185 40 5.37085 39.6276 6.03369 38.9648L20 24.9985L33.9663 38.9648C34.6292 39.6276 35.5282 40 36.4655 40C37.4029 40 38.3019 39.6276 38.9648 38.9648C39.6276 38.3019 40 37.4029 40 36.4655C40 35.5282 39.6276 34.6292 38.9648 33.9663L24.9896 20Z"
                  fill="#ec2a06"
                />
              </svg>
            </div>
          </template>
        </MenuModal>

        <ModalKyc :show="showModalKyc" @close="showModalKyc = false" />
      </Teleport>
    </nav>
  </header>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import MenuModal from "../modals/MenuModal.vue";
import ModalKyc from "../modals/ModalKyc.vue";

export default {
  components: { MenuModal, ModalKyc },
  data() {
    return {
      themain,
      moment,

      showModal: false,
      showModalKyc: false,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      user: {},

      scrollPosition: 0,

      navbarScrolled: "",
      fixedTop: "",

      notifs: [],
      notifs_count: 0,

      metadata: "",

      idmeta: {
        token: null,
        templateId: null,
        verificationId: null,
      },
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected - top nav");
    },

    /**
     * SEMUA_HALAMAN
     * Notifikasi KYC diletakkan disini supaya bisa di load dari halaman manapun
     * @param {*} data
     */
    kyc_status: function (data) {
      var self = this;
      if (data.uid == self.usid) {
        // jika disetujui, infokan untuk lihat email persetujuan
        if (data.message.toLowerCase() == "verified") {
          self.$router.push("/settings/agreement-sign");
        } else if (data.message == "reviewNeeded") {
          alert("KYC Anda sedang dalam proses review");
          self.$router.go();
        } else if (data.message == "rejected") {
          alert(`Status KYC Anda ditolak. Mohon dipastikan NIK Anda belum pernah terdaftar 
          dan pastikan juga NIK yang Anda masukkan terlihat jelas.`);
          self.$router.go();
        }
      }
    },

    kyc_tutup_popup: function (data) {
      var self = this;
      if (data.uid == self.usid) {
        // pada FE, popup menutup sendiri. Jadi do nothing disini
        // console.log("tutup popup KYC");
      }
    },
  },

  mounted() {
    var self = this;

    if (
      self.$router.currentRoute._value.path == "/" ||
      self.$router.currentRoute._value.path == "/auth/login" ||
      self.$router.currentRoute._value.path == "/auth/register" ||
      self.$router.currentRoute._value.path == "/auth/forgot-password" ||
      self.$router.currentRoute._value.path == "/auth/passchange-form" ||
      self.$router.currentRoute._value.path == "/auth/two-factor-auth" ||
      self.$router.currentRoute._value.path ==
        "/auth/email-verification-token-request"
    ) {
      var selected = document.getElementById("login_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }

    if (self.$router.currentRoute._value.path == "/") {
      self.fixedTop = "fixed-top";
    }

    // jika ada token, maka ambil data notifikasi dan juga tambahkan metadata
    if (self.token) {
      self.getMyData();
      self.getMyNotifs();

      const userId = Cookies.get("usid");
      self.metadata = `{"user_id":"${userId}"}`;
    }

    window.addEventListener("scroll", this.updateScrollPosition);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.updateScrollPosition);
  },

  methods: {
    updateScrollPosition() {
      var self = this;
      this.scrollPosition = window.scrollY;

      if (this.scrollPosition > 10) {
        self.navbarScrolled = "navbar--scrolled";
      } else {
        self.navbarScrolled = "";
      }
    },

    async logoutAccount() {
      var self = this;

      var res = await thestore.postAuthLogout(self.token);
      if (res.success == true) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      } else {
        alert(res.msg);
      }
    },
    async getMyData() {
      var self = this;
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    },

    async getMyNotifs() {
      var self = this;
      var filter_rules = [{ field: "read_tf", op: "boolean", value: false }];
      var res = await thestore.getNotifs(
        self.token,
        1,
        1,
        "id",
        "desc",
        filter_rules
      );
      self.notifs = res.datas;
      self.notifs_count = res.datas.length;
    },

    async postClearAllNotifs() {
      var self = this;
      await thestore.postClearNotifs(self.token);
      self.notifs_count = 0;
    },

    async metamapHandler() {
      try {
        const { metaToken, templateId } = await thestore.getConfig();
        this.idmeta.templateId = res.templateId;
        this.idmeta.token = res.metaToken;
        console.log(metaToken, templateId);

        const res = await thestore.createVerification(metaToken, templateId);
        this.idmeta.verificationId = res.id;
        console.log("idMeta: ", res);
      } catch (errr) {
        console.log("Error: ", errr);
      }

      // if (!this.metamap.metamapReady) {
      //   const res = await thestore.getConfig();

      //   if (res.metamapClientId && res.metamapFlowId) {
      //     this.metamap.metamapClientId = res.metamapClientId;
      //     this.metamap.metamapFlowId = res.metamapFlowId;

      //     this.$refs.metamapButton.click();
      //   }

      //   this.metamap.metamapReady = true;
      // }
    },
  },
};
</script>

<style scoped>
#dark,
#dark header {
  background-color: transparent;
  background: transparent;
  border: none;
}
.navbar {
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-out;
}

.navbar--scrolled {
  background-color: #131722; /* Replace with desired background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add a box shadow */
}

.nav_kyc {
  position: relative;
}

/* .nav_kyc button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  margin-left: -8px;
  margin-top: -3px;

  background: none;
  border: 1px solid #fff;
  border-radius: 3px;
} */

.nav_kyc button {
  color: #fff;
  width: 100%;

  background: none;
  border: 1px solid #fff;
  border-radius: 3px;
}

.nav_kyc-update {
  color: #fff;
  background: none;
  padding: 3px 5px;
  /* border: 1px solid #dd2730; */
  border: 1px solid #fff;
  border-radius: 3px;
}
.nav_kyc-update:hover {
  color: #fff !important;
}
.navbar-nav {
  align-items: center;
}
.metamap_btn {
  opacity: 0;
  width: 150px;
}

.bg_color {
  background-color: #131722;
}

.cursor_pointer {
  cursor: pointer;
}

.menu_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.menu_head svg {
  cursor: pointer;
}

#dark header nav a:hover {
  color: #26de81
}

@media only screen and (max-width: 767px) {
  .navbar-collapse {
    background-color: #131722;
  }
}
</style>
