<template>
  <div class="col-md-12 col-lg-3">
    <div
      class="nav flex-column nav-pills settings-nav"
      id="v-pills-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      <router-link
        class="nav-link"
        id="menu__settings_profile"
        to="/settings/profile"
      >
        <i class="icon ion-md-person"></i>
        Profil
      </router-link>
      <router-link
        class="nav-link"
        id="menu__settings_bank"
        to="/settings/bank"
      >
        <i class="icon ion-md-wallet"></i> Bank
      </router-link>
      <router-link
        class="nav-link"
        id="menu__settings_wallets"
        to="/settings/wallets"
      >
        <i class="icon ion-md-wallet"></i> Dompet
      </router-link>
      <router-link class="nav-link" id="menu__settings_2fa" to="/settings/2fa">
        <i class="icon ion-md-key"></i> 2FA
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  async mounted() {
    var self = this;
    var router_name = self.$route.name;
    if (
      router_name == "settings_wallets_deposit" ||
      router_name == "settings_wallets_withdraw" ||
      router_name == "settings_wallets_idr_deposit" ||
      router_name == "settings_wallets_idr_withdraw" ||
      router_name == "settings_wallets_idr_deposit_upload_file" ||
      router_name == "settings_wallets_btc_deposit" ||
      router_name == "settings_wallets_btc_withdraw"
    ) {
      document.getElementById(`menu__settings_wallets`).classList.add("active");
    } else {
      // cegah error jika element tidak ditemukan
      var elem = document.getElementById(`menu__${router_name}`);
      if(elem) {
        elem.classList.add("active");
      }
    }
  },
};
</script>

<style scoped>
.router-link-active {
  background-color: #343A40 !important;
}
.settings .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: #343A40
}
</style>