<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <form @submit.prevent="submitForm">
          <span>Two Factor Authentication</span>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Google Secret"
              v-model="google_secret"
            />
          </div>
          <button type="submit" class="btn btn-gradient">Submit</button>
        </form>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      email: Cookies.get("temp_email"),
      password: Cookies.get("temp_password"),
      google_secret: "",
    };
  },

  async beforeMount() {
    var self = this;

    if (
      self.email &&
      self.email != "" &&
      self.password &&
      self.password != ""
    ) {
      // console.log("do nothing");
    } else {
      Cookies.remove("jwt");
      Cookies.remove("usid");
      Cookies.remove("temp_email");
      Cookies.remove("temp_password");
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;

      if (self.recaptchaToken != "") {
        var res = await thestore.postAuthLoginTwoFa(
          self.email,
          self.password,
          self.google_secret
        );

        if (res.success == true && res.action == "login_complete") {
          Cookies.set("jwt", res.token, { expires: 7 }); // expire 7 days. CAUTION: coba kurangi JIKA PERLU
          Cookies.set("usid", res.usid, { expires: 7 });
          self.$router.push("/");
        } else if (res.success == true && res.action == "2fa") {
          Cookies.set("temp_email", self.email, { expires: 1 });
          Cookies.set("temp_password", self.password, { expires: 1 });
          self.$router.push("/auth/two-factor-auth");
        } else {
          alert(res.msg);
        }
      }
    },
  },
};
</script>
