<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto glass_a">
        <form
          v-if="isSuccess === null"
          class="d-flex justify-content-center py-5"
        >
          <div class="spinner-border" role="status"></div>
        </form>

        <form v-else-if="isSuccess" @submit.prevent>
          <div class="mb-4">
            <SuccessAnimation />
            <h4>{{ pesan }}</h4>
          </div>
          <button
            @click="
              () => {
                $router.push('/auth/login');
              }
            "
            class="btn btn-gradient"
          >
            Masuk
          </button>
        </form>

        <form v-else-if="!isSuccess" @submit.prevent>
          <div class="mb-4">
            <ErrorAnimation class="mb-3" />
            <h4>{{ pesan }}</h4>
          </div>
          <button
            @click="
              () => {
                $router.push('/');
              }
            "
            class="btn btn-gradient"
          >
            Kembali
          </button>
        </form>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import SuccessAnimation from "@/components/animations/SuccessAnimation.vue";
import ErrorAnimation from "@/components/animations/ErrorAnimation.vue";

export default {
  components: {
    TopNav,
    Footer,
    SuccessAnimation,
    ErrorAnimation,
  },
  data() {
    return {
      themain,
      pesan: "",
      isSuccess: null,
    };
  },

  async beforeMount() {
    var self = this;
    var res = await thestore.postAuthEmailVerification(
      self.$route.query.verification_code
    );
    if (res.success == true) {
      self.pesan = res.msg;
      self.isSuccess = true;
    } else {
      self.pesan = res.msg;
      self.isSuccess = false;
    }
  },
};
</script>

<style scoped>
h4 {
  text-align: center;
  font-weight: 700;
}

form {
  max-width: 30rem;
}

.glass_a {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

#dark .form-access form {
  border-radius: 16px;
}
</style>
