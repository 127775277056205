<template>
  <div>
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Bank</h5>

                    <div class="settings-profile">
                      <form @submit.prevent="submitForm">
                        <div class="form-row mt-4">
                          <div class="col-md-12 mb-2">
                            <label for="formFirst">Nama Bank </label>
                            <input
                              v-if="bank.data_lock"
                              type="text"
                              class="form-control"
                              v-model="bank.name"
                            />
                            <select
                              v-else
                              v-model="selectedBank"
                              class="form-control w-100"
                              ref="selectElement"
                            >
                              <option value="" class="">Pilih Bank</option>
                            </select>
                            <!-- <select
                              v-model="selectedBank"
                              class="form-control select2"
                            >
                              <option disabled value="">Pilih bank</option>
                              <option
                                v-for="item in banks"
                                :key="item.code"
                                :value="item.code"
                                @click="handleClick(item.name)"
                              >
                                {{ item.name }}
                              </option>
                            </select> -->

                            <!-- <input
                              style="color: #2a2e39"
                              id="formFirst"
                              type="text"
                              class="form-control"
                              placeholder="Nama Bank"
                              v-model="bank.name"
                            /> -->
                          </div>
                          <div class="col-md-12 mb-2">
                            <label for="formLast">Nama Akun</label>
                            <input
                              style="color: #ffff"
                              id="formLast"
                              type="text"
                              class="form-control"
                              placeholder="Nama Akun"
                              v-model="bank.ac_name"
                            />
                          </div>
                          <div class="col-md-12 mb-2">
                            <label for="emailAddress">Nomor Akun</label>
                            <input
                              style="color: #ffff"
                              id="emailAddress"
                              type="text"
                              class="form-control"
                              placeholder="Nomor Akun"
                              v-model="bank.ac_number"
                            />
                          </div>

                          <div
                            class="col-md-12 mt-4"
                            v-if="bank.data_lock == false"
                          >
                            <button class="btn btn-gradient">Simpan</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// json
// import banks from "../../banks/bank.json";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      banks: null,
      selectedBank: "",
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
      bank: {
        data_lock: true,
      },
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    await self.getMyBank();

    await self.getBankList();
    self.handleSelect();
  },

  beforeDestroy() {
    $(this.$refs.selectElement).select2("destroy");
  },

  methods: {
    async submitForm() {
      var self = this;
      var res = await thestore.postMyBank(
        self.token,
        self.selectedBank,
        self.bank.ac_name,
        self.bank.ac_number
      );
      if (res.success == true) {
        self.$router.go();
      } else {
        toast.warning(res.msg);
      }
    },

    async getMyBank() {
      var self = this;
      var res = await thestore.getMyBank(self.token);
      // console.log("res", res);
      self.bank = res;
    },

    async getBankList() {
      const res = await thestore.getBankList();
      const bankList = res.data.listsBank;

      this.filterBank(bankList);
    },

    filterBank(banks) {
      this.banks = banks.filter((bank) => bank.can_disburse);
    },

    handleSelect() {
      var self = this;

      $(self.$refs.selectElement)
        .select2({
          // Opsi Select2 jika diperlukan
        })
        .on("change", (event) => {
          // Simpan nilai yang dipilih ke dalam v-model
          self.selectedBank = event.target.value;
        });

      // opsi select dengan data bank
      self.banks.forEach((bank) => {
        $(self.$refs.selectElement).append(
          `<option value="${bank.code}">${bank.name}</option>`
        );
      });
    },
  },
};
</script>

<style>
.select2-container--default .select2-selection--single {
  width: 100%;
  background-color: #2a2e39;
  border: none;
}
.select2-selection__rendered {
  color: rgb(141, 141, 141) !important;
}

.select2-dropdown {
  background-color: #2a2e39;
  color: aliceblue;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #4b4f5a;
}

.select2-search__field {
  background-color: #2a2e39;
  color: aliceblue;
  border: #4b4f5a;
}

.select2-container {
  width: 100% !important;
}
</style>
