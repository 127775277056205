<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Informasi Umum</h5>

                    <!-- <div class="custom-file">
                      <input type="file" class="custom-file-input" id="fileUpload" ref="fileInput">
                      <label class="custom-file-label" for="fileUpload">Choose avatar</label>

                      <button @click="uploadFile">Upload</button>
                    </div> -->

                    <div class="settings-profile">
                      <form @submit.prevent="submitForm">
                        <!-- <img src="assets/img/avatar.svg" alt="avatar" >
                        <div class="custom-file">
                          <input type="file" class="custom-file-input" id="fileUpload" >
                          <label class="custom-file-label" for="fileUpload">Choose avatar</label>
                        </div> -->
                        <div class="form-row mt-4">
                          <div class="col-md-6">
                            <label for="formFirst">Nama Lengkap</label>
                            <input
                              id="formFirst"
                              type="text"
                              class="form-control"
                              placeholder="Full name"
                              disabled
                              v-model="user.orang_name"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="formLast">Panggilan</label>
                            <input
                              id="formLast"
                              type="text"
                              class="form-control"
                              placeholder="Panggilan"
                              v-model="user.orang_nickname"
                            />
                          </div>
                          <div class="col-md-6">
                            <label for="emailAddress">Email</label>
                            <input
                              id="emailAddress"
                              type="text"
                              class="form-control"
                              placeholder="Enter your email"
                              disabled
                              v-model="user.orang_email"
                            />
                          </div>
                          <!-- <div class="col-md-6">
                            <label for="phoneNumber">Telepon</label>
                            <input
                              id="phoneNumber"
                              type="text"
                              class="form-control"
                              placeholder="Enter phone number"
                            />
                          </div> -->

                          <div class="col-md-12">
                            <input
                              type="submit"
                              value="Update"
                              @click="submit"
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import axios from "axios";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;

      try {
        var res = await thestore.postMyProfileData(self.token, self.user);
        self.user = res;
        toast.success("Data berhasil di update");
      } catch (err) {
        console.log("Error: ", err);
      }
    },

    uploadFile() {
      var self = this;

      const file = this.$refs.fileInput.files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(`${themain.baseApi}/users/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            JWT: self.token,
          },
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped></style>
