<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <div>
          <span>Buat Akun</span>
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Nama"
              v-model="name"
              @click="isError = false"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
              @click="isError = false"
            />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
              @click="isError = false"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Ulangi Password"
              ref="rePassword"
              v-model="password_confirm"
              @click="isError = false"
            />
            <TogglePassword @is-password-show="toggleRePasswordValue" />
          </div>
          <div class="custom-control custom-checkbox mb-4">
            <input
              type="checkbox"
              class="custom-control-input"
              id="form-checkbox"
              v-model="checkbox"
              @click="handleShowModal"
            />
            <label class="custom-control-label" for="form-checkbox">
              Saya setuju dengan
              <a
                href="/pages/term-and-conditions"
                class="primary-link"
                target="_blank"
                >Syarat dan Ketentuan.</a
              >
            </label>
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button
            v-if="!isLoading"
            @click="handleSubmit"
            type="button"
            class="btn btn-gradient"
          >
            Buat Akun
          </button>
          <button v-else disabled type="button" class="btn btn-gradient">
            Loading...
          </button>
        </div>
        <h2>
          Sudah Mempunyai Akun?
          <router-link to="/auth/login" class="primary-link"
            >Masuk disini</router-link
          >
        </h2>

        <div v-show="isError" class="err_box">
          <p>{{ errorMessage }}</p>
        </div>
      </div>
    </div>

    <Footer />

    <Teleport to="body">
      <!-- use the modal component, pass in the prop -->
      <TermCondition :show="showModal" @close="showModal = false">
        <template #header>
          <h3 class="header_m">Syarat dan Ketentuan</h3>
        </template>
        <template #footer>
          <div class="btn_gr d-flex">
            <button v-if="isDisabled" class="isDisabled">Menerima</button>
            <button v-else @click="handleAccept" class="btn-gradient">
              Menerima
            </button>
            <button @click="handleCloseModal" class="btn_secondary">
              Menolak
            </button>
          </div>
        </template>
        <template #scrollDown>
          <div @click="scrollToDown" class="scroll_dwn">
            <svg
              width="40"
              height="40"
              viewBox="0 0 142 142"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_902_5-899871)">
                <circle cx="71" cy="67" r="67" fill="#EDF2F5" />
              </g>
              <path
                d="M34.9316 78.3721C32.3561 75.7741 32.3561 71.5618 34.9316 68.9638C37.5072 66.3658 41.6829 66.3658 44.2584 68.9638L71 95.939L97.7416 68.9638C100.317 66.3658 104.493 66.3658 107.068 68.9638C109.644 71.5618 109.644 75.7741 107.068 78.3721L75.6634 110.051C73.0879 112.65 68.9121 112.65 66.3366 110.051L34.9316 78.3721ZM34.9316 40.3568C32.3561 37.7588 32.3561 33.5466 34.9316 30.9485C37.5072 28.3505 41.6829 28.3505 44.2584 30.9485L71 57.9238L97.7416 30.9485C100.317 28.3505 104.493 28.3505 107.068 30.9485C109.644 33.5466 109.644 37.7588 107.068 40.3568L75.6634 72.0362C73.0879 74.6342 68.9121 74.6342 66.3366 72.0362L34.9316 40.3568Z"
                fill="#8D99AF"
              />
              <defs>
                <filter
                  id="filter0_d_902_5-899871"
                  x="0"
                  y="0"
                  width="142"
                  height="142"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_902_5"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_902_5"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </template>
      </TermCondition>
    </Teleport>
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TermCondition from "@/components/modals/TermConditionModal.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    TermCondition,
    VueRecaptcha,
    TogglePassword,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      name: "",
      email: "",
      password: "",
      password_confirm: "",
      checkbox: false,
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
      showModal: false,
      isDisabled: true,
      isError: false,
      errorMessage: null,
      isLoading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }

    self.handleScroll();
  },

  methods: {
    async submitForm() {
      var self = this;

      self.isLoading = true;
      if (self.recaptchaToken != "") {
        if (self.password == self.password_confirm) {
          var res = await thestore.postRegister(
            self.name,
            self.email,
            self.password,
            self.password_confirm,
            self.recaptchaToken
          );
          if (res.success == true) {
            toast.success(res.msg);
            self.name = "";
            self.email = "";
            self.password = "";
            self.password_confirm = "";
            self.$router.push("/auth/login");
          } else {
            toast.warning(res.msg);
            if (res.msg == "Wrong captcha") {
              self.$router.go();
            }
          }

          self.isLoading = false;
        } else {
          toast.warning("Password confirmation is not the same");
        }
      }
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    handleScroll() {
      var self = this;
      const termsEl = document.querySelector(".modal-body");

      if (termsEl) {
        termsEl.addEventListener("scroll", (e) => {
          if (termsEl.scrollTop + window.innerHeight >= termsEl.scrollHeight) {
            self.isDisabled = false;
            // console.log("ok");
          }
        });
      }
    },

    scrollToDown() {
      var self = this;
      const termsEl = document.querySelector(".modal-body");

      if (termsEl) {
        const position = termsEl.scrollHeight;
        termsEl.scrollTo(0, position);
      }
    },

    // method ini untuk menghandle modal
    // untuk mendetaksi scrool
    // baca class ".modal-body"
    // karna class tsb baru muncul saat ada modal maka jalankan handleScroll saat modal true
    // kasih delay biar bener2 dapat classnya
    handleSubmit() {
      var self = this;

      const emailFormat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const formatPassword = /^(?=.*[A-Z])(?=.*[a-zA-Z0-9])(?=.*[\W_]).*$/;

      self.isError = false;

      if (self.name == "") {
        self.isError = true;
        self.errorMessage = "Nama tidak boleh kosong";
      }

      if (self.email == "") {
        self.isError = true;
        self.errorMessage = "Email tidak boleh kosong";
      } else if (!emailFormat.test(self.email)) {
        self.isError = true;
        self.errorMessage = "Email tidak valid";
      }

      if (self.password == "") {
        self.isError = true;
        self.errorMessage = "Password tidak boleh kosong";
      } else if (self.password.length < 8) {
        self.isError = true;
        self.errorMessage = "Password min 8 karakter";
      } else if (!formatPassword.test(this.password)) {
        self.isError = true;
        self.errorMessage =
          "Password harus terdiri dari kombinasi huruf dan angka, dan harus mengandung setidaknya satu huruf besar serta satu karakter spesial.";
      }

      if (self.password_confirm == "") {
        self.isError = true;
        self.errorMessage = "Confirm password tidak boleh kosong";
      } else if (self.password_confirm !== self.password) {
        self.isError = true;
        self.errorMessage = "Password tidak sama";
      }

      if (self.recaptchaToken == "") {
        self.isError = true;
        self.errorMessage = "Pastikan Anda bukan robot";
      }

      if (self.checkbox == false) {
        self.isError = true;
        self.errorMessage = "Syarat dan ketentuan wajib di setujui";
      }

      if (self.isError == false) {
        self.submitForm();
        // self.showModal = true;

        // setTimeout(() => {
        //   self.handleScroll();
        // }, 200);
      }
    },

    handleShowModal() {
      var self = this;

      self.showModal = true;
      setTimeout(() => {
        self.handleScroll();
      }, 200);
    },

    handleAccept() {
      var self = this;

      self.isError = false;
      self.showModal = false;
    },

    handleCloseModal() {
      var self = this;

      self.isError = true;
      self.errorMessage = "Syarat dan ketentuan wajib disetujui";
      self.checkbox = false;
      self.showModal = false;
      self.isDisabled = true;
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleRePasswordValue(val) {
      const rePassword = this.$refs.rePassword;
      rePassword.type = val ? "text" : "password";
    },
  },
};
</script>

<style scoped>
.btn_gr button {
  width: 130px;
  height: 35px;
  border: none;
  border-radius: 5px;
}

.btn_secondary {
  margin-left: 15px;
}
.isDisabled {
  background: grey;
  cursor: not-allowed;
  color: #fff;
}

.scroll_dwn {
  position: fixed;
  bottom: 20%;
  cursor: pointer;
}

.header_m {
  font-weight: bold;
  font-size: 20px;
}

.err_box {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 340px;
  min-height: 60px;
  background-color: palevioletred;
  border-radius: 5px;
  padding: 5px 10px;
}

.err_box p {
  margin: 0;
}

@media (max-width: 500px) {
  .header_m {
    font-size: 16px;
  }
}
</style>
