<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <form @submit.prevent="submitForm">
          <span>Permintaan Verifikasi Email</span>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button type="submit" class="btn btn-gradient">kirim</button>
        </form>
        <h2>
          Apakah Anda ingat kata sandi Anda?
          <router-link to="/auth/login" class="primary-link">
            Klik di sini
          </router-link>
        </h2>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
  },
  data() {
    return {
      themain,
      email: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
    };
  },

  methods: {
    async submitForm() {
      var self = this;

      if (self.recaptchaToken != "") {
        var res = await thestore.postAuthEmailVerificationRequest(
          self.email,
          self.recaptchaToken
        );

        if (res.success == true) {
          alert(res.msg);
          self.$router.push("/");
        } else {
          alert(res.msg);
          if (res.msg == "Wrong captcha") {
            self.$router.go();
          }
        }
      }
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },
  },
};
</script>
