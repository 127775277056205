<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <div>
          <span>Change Password</span>
          <div class="form-group" style="display: none">
            <input type="text" v-model="name" />
          </div>
          <div class="form-group" style="display: none">
            <input type="text" v-model="email" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Password"
              ref="password"
              v-model="password"
            />
            <TogglePassword @is-password-show="togglePasswordValue" />
          </div>
          <div class="form-group position-relative">
            <input
              type="password"
              class="form-control"
              placeholder="Confirm Password"
              ref="rePassword"
              v-model="password_confirm"
            />
            <TogglePassword @is-password-show="toggleRePasswordValue" />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button
            v-if="!isLoading"
            @click="submitForm"
            type="submit"
            class="btn btn-gradient"
          >
            Submit
          </button>
          <button disabled class="btn btn-gradient" v-else>Loading...</button>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import TogglePassword from "@/components/global/TogglePassword.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
    TogglePassword,
  },
  data() {
    return {
      themain,
      name: "",
      email: this.$route.query.email,
      token: this.$route.query.token,
      password: "",
      password_confirm: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
      isLoading: false,
    };
  },

  methods: {
    async submitForm() {
      var self = this;

      self.isLoading = true;

      if (self.recaptchaToken != "") {
        if (self.password == self.password_confirm) {
          var res = await thestore.postAuthChangePassword(
            self.email,
            self.token,
            self.password,
            self.password_confirm,
            self.recaptchaToken
          );
          if (res.success == true) {
            toast.success(res.msg);
            self.$router.push("/auth/login");
          } else {
            toast.warning(res.msg);
            if (res.msg == "Wrong captcha") {
              self.$router.go();
            }
          }
        } else {
          toast.warning("Password confirmation is not the same");
        }
        self.isLoading = false;
      }
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },

    togglePasswordValue(val) {
      const password = this.$refs.password;
      password.type = val ? "text" : "password";
    },

    toggleRePasswordValue(val) {
      const rePassword = this.$refs.rePassword;
      rePassword.type = val ? "text" : "password";
    },
  },
};
</script>
