<template>
  <div>
    <div class="btn btn-light" @click="requestOtp">Request OTP</div>
  </div>
</template>

<script>
// TODO: buatkan count down otp-nya

// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

export default {
  components: {},
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
    };
  },
  methods: {
    async requestOtp() {
      var self = this;

      var res = await thestore.postRequestOtp(self.token);
      if (res.success == true) {
        alert("Please find the OTP in your email inbox.");
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>
